import React from 'react'
import { IHomeWorkTextProps } from './types'
import Marquee from 'react-fast-marquee'
import { functions } from '@wap-client/core'

const HomeWorkText: React.FC<IHomeWorkTextProps> = ({
  data = [],
  duration = 50,
  onHover = false,
}) => {
  if (data.length > 0) {
    return (
      <div className="home-work-text-banner">
        <Marquee autoFill pauseOnHover={onHover} speed={duration}>
          {data.map((marquee, index) => {
            const text = marquee?.marqueeText?.toLocaleUpperCase('en-GB')

            if (data.length > 1) {
              return (
                <span
                  key={index}
                  className={functions.classnames(
                    'marquee-text',
                    index % 2 === 1 && 'marquee-text-bold'
                  )}
                >
                  {text}
                </span>
              )
            } else {
              return (
                <div key={index}>
                  <span className="marquee-text">{text}</span>
                  <span className="marquee-text marquee-text-bold">{text}</span>
                </div>
              )
            }
          })}
        </Marquee>
      </div>
    )
  } else return
}

export default HomeWorkText
